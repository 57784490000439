@use '_variables' as *
    
.bridge-wrapper
    display: flex
    .bridge-left-nav-wrapper
        background-color: white
        width: 200px
        margin-right: 12px
        height: 300px
        border-radius: 10px
        box-shadow: 0 3px 6px rgba(0,0,0,.24)
        display: flex
        flex-direction: column
        padding: 24px
        box-sizing: border-box
        flex-shrink: 0
        .nav-link
            color: $ultraviolet
            line-height: 32px
            &.active
                font-weight: bold
                color: $nighttime
    .bridge-content-wrapper
        width: 80%



// pouch library component styles
.pouch-modal-wrapper
    width: 100vw
    height: 100vh
    background-color: rgba(0,0,0,.5)
    position: fixed
    top: 0
    left: 0
    z-index: 9999
    .pouch-modal
        width: 80%
        height: 80%
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: white
        border-radius: 10px
        box-shadow: 0 3px 6px rgba(0,0,0,.24)
        padding: 48px
        box-sizing: border-box
        font-size: 18px
        .modal-content
            overflow: auto
            height: 100%
        .close-button
            position: absolute
            top: 0
            right: 12px
            padding: 12px
            transition: .2s
            font-size: 36px
            cursor: pointer
            &:hover
                opacity: .5


.pouch-button
    background-color: $ultraviolet
    color: white
    border-radius: 4px
    border-color: transparent
    width: 100%
    padding: 8px 12px
    text-transform: uppercase
    letter-spacing: 1.3px
    font-weight: 500
    transition: .2s
    &.disabled
        opacity: .5
        pointer-events: none
    &:hover
        opacity: .5
        cursor: pointer

.user-tags-icon-wrapper
    cursor: pointer
    border-radius: 50%
    display: inline-flex
    justify-content: center
    align-items: center
    width: 36px
    height: 36px