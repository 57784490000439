@use '_variables' as *
    
.profile
    min-height: 100vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    background-color: $light-gray
    .profile-card
        background-color: white
        width: 100%
        max-width: 300px
        margin: 12px
        padding: 24px
        box-shadow: 0 3px 6px #9288883d
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        text-align: center
        border-radius: 10px
        position: relative
        overflow: hidden
        .username
            font-size: 20px
            font-weight: bold
        .tip-greeting
            font-style: italic
            margin-top: 12px
        .tip-amount-input-wrapper
            margin: 72px 0 0
            position: relative
            .tip-amount-input
                border: 1px solid transparent
                border-bottom: 1px solid $dark-gray
                padding: 6px
                box-sizing: border-box
                width: 100%
                text-align: center
                font-size: 48px
                outline: none
        .tip-currency-symbol
            position: absolute
            bottom: 12px
            right: 0
            font-size: 12px
        .connection-indicator-wrapper
            position: absolute
            top: 38px
            right: 24px
            transition: .2s
            cursor: pointer
            &:hover
                opacity: .5
            .connection-indicator
                width: 8px
                height: 8px
                border-radius: 50%
            .connection-indicator-text
                position: absolute
                right: 12px
                top: -2px
                font-size: 10px
                text-transform: uppercase
                color: $nighttime
        .qr-card-amount
            font-size: 24px
            margin: 32px 0 24px
        
.submit-button
    background-color: $ultraviolet
    color: white
    border-radius: 4px
    border-color: transparent
    width: 100%
    padding: 10px 12px
    text-transform: uppercase
    letter-spacing: 1.3px
    font-weight: 500
    transition: .2s
    margin-top: 24px
    &.disabled
        opacity: .5
        pointer-events: none
    &:hover
        opacity: .5
        cursor: pointer
.tip-memo-input
    border: 1px solid transparent
    border-bottom: 1px solid $dark-gray
    padding: 6px
    box-sizing: border-box
    width: 100%
    text-align: center
    outline: none
    margin: 36px 0 24px
.add-memo-button
    color: $ultraviolet
    background-color: transparent
    border: none
    cursor: pointer
    transition: .2s
    margin: 12px 0 24px
    font-size: 11px
    align-self: flex-end
    &:hover
        opacity: .75
.qr-card-memo
    margin-bottom: 32px
    font-style: italic