@use '_variables' as *
    
.fade-in-wrapper
    animation: fadeInDown .8s
@keyframes fadeInDown
    from
        margin-top: -60px
        opacity: 0
    to
        margin-top: 0
        opacity: 1

.splash-section-1
    padding: 48px 12px
    text-align: center
    margin-top: 48px
    max-width: 600px
    margin: 0 auto
    .splash-headline
        font-size: 48px
        margin-top: 48px
        color: $ultraviolet
        font-weight: bold
.splash-download-badges
    display: flex
    justify-content: center
    align-items: center
    margin-top: 48px
    flex-wrap: wrap

.splash-section-2
    overflow: hidden
    position: relative
    .slanted-background-1
        background-color: $ultraviolet
        margin: 12% -50%
        width: 200%
        height: 600px
        transform: rotate(9deg)
    .slanted-background-2
        width: 0
        height: 0
        bottom: 0
        position: absolute
        border-right: 130vw solid transparent
        border-bottom: 20vw solid $ultraviolet
    .slanted-section-content
        color: white
        position: absolute
        top: 15vw
        padding: 48px 10vw
        width: 50%
        max-width: 680px
        .header-text
            font-size: 36px
            font-weight: bold
        .content-text
            margin-top: 64px
            font-size: 20px
    .slanted-section-screenshot
        position: absolute
        top: 0
        right: 10vw
        width: 20vw
        max-width: 480px
        img
            width: 100%

.splash-subtitle
    font-size: 24px
.bootcamp-content-wrapper
    max-width: 840px
    padding: 48px 5vw 96px
    margin: 0 auto
    text-align: center
    font-size: 22px
    line-height: 1.5

.splash-lnd-uri
    font-size: 10px
    border-radius: 100px
    padding: 8px 16px
    margin: 2px 10px
    word-wrap: anywhere
    overflow-wrap: anywhere
    word-break: break-all
    text-align: center
    max-width: 80%
    box-sizing: border-box
    
@media (max-width: $tablet-breakpoint)
    .splash-section-1
        .splash-headline
            font-size: 24px
    .splash-section-2
        .slanted-section-content
            width: 100%
            box-sizing: border-box
            .header-text
                font-size: 24px
            .content-text
                font-size: 18px
        .slanted-background-1
            height: 900px
        .slanted-section-screenshot
            top: auto
            right: auto
            bottom: 0
            width: 40vw
            left: 50%
            transform: translateX(-50%)
            